import styled from 'styled-components';

export const HeaderWrapper = styled.header`
.header{
    max-width: 960px;
    display:flex;
    padding:25px;
    // margin-right:0px;
    // margin-left: 0px;
    background-image: linear-gradient(white,rgb(236,236,236));
}
// .navbar-collapse{
//     margin-right:15px;
//     background-image: linear-gradient(white,rgb(236,236,236));

// }
.header-number{
    margin-right:15px;
    text-align: right;
    padding: 15px 0px;

}
a {
    color: #337ab7;
    text-decoration: none;
    background-color: transparent;
  }
.header-number span {
    color: #2e308f;
}
.link {
    position: relative;
    display: block;
    padding: 10px 15px;
}
.link:hover{
    color:E8E8E8
}

`;
