import React from 'react';
import { HeaderWrapper } from './header.css';
import Logo from "../../images/slider/logo.png";
import "scss/main.scss"
import {  Nav } from 'react-bootstrap'



const Header = () => {
  return (
    <HeaderWrapper>
      <div className="header" style={{padding:`20px;`}}>
        <div>
          <a className="navbar-brand" href="/"><img src={Logo} alt="SURAAJCONSULTANT" title="SURAAJCONSULTANT" className="img-responsive"></img></a>
        </div>
        <div className="navbar-collapse navbar-wrapper">
          <div className="header-number"><span>Get in touch:</span> +91 44 48605078 </div>
          <div>
            <Nav className="justify-content-end" activeKey="/home">
                <Nav.Link href="/" className="link">Home</Nav.Link>
                <Nav.Link href="/about" className="link">About Us</Nav.Link>
                <Nav.Link href="/services" className="link">Services</Nav.Link>
                <Nav.Link href="/gallery" className="link">Gallery</Nav.Link>
                <Nav.Link href="/contact" className="link">Contact Us</Nav.Link>
            </Nav>
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
