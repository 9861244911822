import React from 'react';
import { ClientLogoSection } from './clientsLogo.css';
import { useStaticQuery, graphql } from "gatsby";

const ClientLogo = () => {
  const data = useStaticQuery(graphql`
  query MyQuery {
    allStrapiClientLogo {
      nodes {
        logo {
          image {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  }  
  `)
  const node = data.allStrapiClientLogo.nodes[0];
  console.log("data image",node.logo)
  return (
    <ClientLogoSection>
      <div className="client-block">
        <div className="client-wrap">
          <div id="Carousel" className="carousel slide" >
            <div className="carousel-inner" >
              <div className="item active"  >
                <div className="client-items">
                  {
                    node.logo.map(item => {
                      return (
                        <div className="col-md-2  col-sm-4  col-xs-6">
                          <a className="thumbnail">
                            <img src={item.image.childImageSharp.fluid.src} alt="Suraaj Consultants"></img>
                          </a>
                          </div>
                        );
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ClientLogoSection>
  );
};

export default ClientLogo;