import React from 'react';
import { FooterWrapper } from './footer.css';
import "scss/main.scss";
import { useStaticQuery, graphql } from "gatsby";


const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiFooter {
        nodes {
          address
          mail
          mobile
          contact
        }
      }
    }
  `)
const node = data.allStrapiFooter.nodes[0];
  return ( 
    <FooterWrapper>
      <footer class="col-lg-12">
        <div class="footer-section">
          <div class="row">
            <div class="col-md-4">
              <iframe width="100%" height="130px" src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=Chennai&ie=UTF8&t=&z=12&iwloc=B&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
              </iframe>
            </div>
            <div class="col-md-4 col-sm-4">
              <p>{node.address}</p>
            </div>
          </div>
        </div>
      </footer>
    </FooterWrapper>

  );
};

export default Footer;
